import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import ResourceCard from '../../elements/ResourceCard/ResourceCard';

const ResourceGrid = tw.div`
  grid gap-x-6 gap-y-7 md:grid-cols-2 lg:grid-cols-3 mx-auto md:w-max
`;

const Resources = ({resources, ...props }) => {

  return (
    <ResourceGrid>
      {resources.map( (resource, resourceIndex) => {
        return (
          <ResourceCard
            key={`resource_${resource.node.name}_${resourceIndex}`}
            {...resource.node}
          />
        );
      })}
    </ResourceGrid>
  );
}

Resources.propTypes = {
  resources: PropTypes.array,
}

Resources.defaultProps = {
  resources: [],
}

export default Resources;