import React from 'react';
import PropTypes from 'prop-types';

const TopicHeader = ({name, description, background, ...props}) => {

  return (
      <div
        className={`bg-gray-400 rounded-lg px-6 py-5 md:px-8 md:py-7 h-48 flex flex-col justify-end items-start bg-cover bg-center`}
        {...props}
        style={{
          ...(background && {backgroundImage: `url(${background.publicURL})`}),
        }}
      >
        <h1 className='text-black font-extrabold font-heading text-2xl md:text-3xl mb-1 uppercase'>{name}</h1>
        <p className='text-black text-base font-light md:text-lg'>{description}</p>
      </div>
  );
};

TopicHeader.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  background: PropTypes.object,
};

TopicHeader.defaultProps = {
  name: null,
  description: null,
  background: null,
}

export default TopicHeader;