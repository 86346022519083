import React from 'react';
import { Helmet } from 'react-helmet';

import Container from "components/elements/Container/Container";
import Resources from 'components/layouts/Resources/Resources';
import TopicHeader from 'components/elements/TopicHeader/TopicHeader';


const TopicListingScreen = ({data}) => {
  return (
    <>
      <Helmet
        title={data.strapiTopic.Name}
        />
      <Container className="space-y-10">
        <TopicHeader name={data.strapiTopic.Name} description={data.strapiTopic.Description} background={data.strapiTopic.Background}/>
        <Resources resources={data.allStrapiResource.edges}/>
      </Container>
    </>
  );
};

export default TopicListingScreen;