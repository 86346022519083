// create the route example.com/topics/topic-id
// this will dynamicaly create the topic pages
import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import TopicListingScreen from 'components/screens/TopicListingScreen/TopicListingScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <TopicListingScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query topicDetail($strapiId: Int) {
  strapiTopic(strapiId: {eq: $strapiId}) {
    Name
    Description
    Background {
      publicURL
    }
  }
  allStrapiResource(filter: {topic: {id: {eq: $strapiId}}}) {
    edges {
      node {
        strapiId
      	name
      	time_description
      	type
      	file {
      	  publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      	}
        image_story_thumbnail {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        topic {
          Name
        }
      }
    }
  }
}
`;
